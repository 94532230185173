import { Url, Mode as ModeType } from "routes/paths";
import Routes from "./Routes";
import { getBreads, getBreadsMagazzino } from "routes/breadcrumbs";
import { history } from "App";

export const goTo = (url: string, state?: any) => history.push(url, state)

export { Routes, Url, getBreads, getBreadsMagazzino };
export type Mode = ModeType;
export default Routes;
