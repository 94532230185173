import React, { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router";
import PublicRoute from "routes/components/PublicRoute";
import PrivateRoute from "routes/components/PrivateRoute";
import { Url } from "routes";

const LoginPage = lazy(() => import("pages/login/LoginPage"));
const ForgotPasswordPage = lazy(
  () => import("pages/forgot-password/ForgotPasswordPage")
);
const ResetPasswordPage = lazy(
  () => import("pages/reset-password/ResetPasswordPage")
);
const ActivateAccountPage = lazy(
  () => import("pages/attivazione-account/AttivazioneAccount")
);
const CreateUser = lazy(() => import("pages/attivazione-account/CreateUser"));
const GestioneUtentiPage = lazy(
  () => import("pages/configurazione/utenti/GestioneUtentiPage")
);
const GestioneUtentiBackPage = lazy(
  () => import("pages/configurazione-backoffice/utenti/GestioneUtentiPage")
);
const MagazzinoPage = lazy(() => import("pages/magazzino/MagazzinoPage"));
const AggiornaMagazzinoPage = lazy(
  () => import("pages/magazzino/AggiornaMagazzinoPage")
);
const MagazzinoFederatoPage = lazy(
  () => import("pages/magazzino-federato/MagazzinoFederatoPage")
);
const MagazzinoFederatoBackPage = lazy(
  () => import("pages/magazzino-federato-backoffice/MagazzinoFederatoPage")
);
const OrdiniPage = lazy(() => import("pages/ordini/OrdiniPage"));
const OrdiniBackPage = lazy(() => import("pages/ordini-backoffice/OrdiniPage"));
const OrdineDettaglioPage = lazy(
  () => import("pages/ordini/dettaglio/OrdineDettaglioPage")
);
const BrandsPage = lazy(() => import("pages/brands/BrandsPage"));
const NuovoBrandPage = lazy(() => import("pages/brands/NuovoBrandPage"));
const ModificaBrandPage = lazy(() => import("pages/brands/ModificaBrandPage"));
const ResiPage = lazy(() => import("pages/resi/ResiPage"));
const ResiBackPage = lazy(() => import("pages/resi-backoffice/ResiPage"));
const ResoDettaglioPage = lazy(
  () => import("pages/resi/dettaglio/ResoDettaglioPage")
);
const StatistichePage = lazy(() => import("pages/statistiche/StatistichePage"));
const FederatiPage = lazy(() => import("pages/societa-federate/FederatiPage"));
const CreazioneFederatiPage = lazy(
  () => import("pages/societa-federate/gestione/CreazionePage")
);
const ModificaFederatiPage = lazy(
  () => import("pages/societa-federate/gestione/ModificaPage")
);
const NotifichePage = lazy(() => import("pages/notifiche/NotifichePage"));
const HelpPage = lazy(() => import("pages/help/HelpPage"));
const NuovoTicketPage = lazy(() => import("pages/help/NuovoTicketPage"));
const DettaglioTicketPage = lazy(
  () => import("pages/help/DettaglioTicketPage")
);
const TechSupportPage = lazy(
  () => import("pages/tech-support/TechSupportPage")
);
const ProdottoPage = lazy(
  () => import("pages/magazzino/dettaglio/ProdottoPage")
);
const ProdottoSchedaPage = lazy(
  () => import("pages/magazzino/dettaglio/ProdottoSchedaPage")
);
const ProfiloBackPage = lazy(
  () => import("pages/profilo-backoffice/ProfiloPage")
);
const ProfiloPage = lazy(() => import("pages/profilo/ProfiloPage"));
const AppSocialCommercePage = lazy(
  () => import("pages/app-social-commerce/AppSocialCommercePage")
);
const AppSocialCommerceBackPage = lazy(
  () => import("pages/app-social-commerce-backoffice/AppSocialCommercePage")
);
const PreferenzePage = lazy(
  () => import("pages/app-social-commerce/PreferenzePage")
);
const PrimoAccesso = lazy(() => import("pages/primo-accesso/PrimoAccesso"));
const AttivazioneUtente = lazy(
  () => import("pages/attivazione-utente/AttivazioneUtente")
);
const RetailerProfile = lazy(
  () => import("pages/retailer-profile/RetailerProfile")
);
const NotFoundPage = lazy(
  () => import("./components/error-pages/NotFoundPage")
);

const InfoRetailerRegistration = lazy(
  () => import("pages/info-retailer-registration/InfoRetailerRegistration")
);

const Routes = () => (
  <Switch>
    <PublicRoute
      path={Url.login}
      drawer={false}
      header={false}
      footer={false}
      component={LoginPage}
    />
    <PublicRoute
      path={Url.forgot}
      drawer={false}
      header={false}
      footer={false}
      component={ForgotPasswordPage}
    />
    <PublicRoute
      path={Url.reset}
      drawer={false}
      header={false}
      footer={false}
      component={ResetPasswordPage}
    />
    <PublicRoute
      path={Url.activate}
      drawer={false}
      header={false}
      footer={false}
      component={ActivateAccountPage}
    />
    <PublicRoute
      path={Url.createuser}
      drawer={false}
      header={false}
      footer={false}
      component={CreateUser}
    />
    <PublicRoute
      path={Url.notfound}
      drawer={false}
      header={false}
      footer={false}
      component={NotFoundPage}
    />
    <PublicRoute
      path={Url.activate_user}
      drawer={false}
      header={false}
      footer={false}
      component={PrimoAccesso}
    />

    <PublicRoute
      path={Url.primo_accesso}
      drawer={false}
      header={false}
      footer={false}
      component={AttivazioneUtente}
    />

    <PublicRoute
      path={Url.retailer_profile}
      drawer={false}
      header={false}
      footer={false}
      component={RetailerProfile}
    />

    <PublicRoute
      path={Url.info_retailer_registration}
      component={InfoRetailerRegistration}
      header={false}
      drawer={false}
      footer={false}
    />

    <PrivateRoute path={Url.profilo} component={ProfiloPage} />
    <PrivateRoute path={Url.profilobackoffice} component={ProfiloBackPage} />
    <PrivateRoute
      path={Url.utentibackoffice}
      component={GestioneUtentiBackPage}
    />
    <PrivateRoute
      path={Url.Configurazione.Utenti.gestione}
      component={GestioneUtentiPage}
    />
    <PrivateRoute exact={true} path={Url.home} component={MagazzinoPage} />
    <PrivateRoute
      exact={true}
      path={Url.aggiornamagazzino}
      component={AggiornaMagazzinoPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.aggiornascalarini}
      component={AggiornaMagazzinoPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.societafederate}
      component={FederatiPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.societafederate_creazione}
      component={CreazioneFederatiPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.societafederate_modifica}
      component={ModificaFederatiPage}
    />
    <PrivateRoute exact={true} path={Url.ordini} component={OrdiniPage} />
    <PrivateRoute
      exact={true}
      path={Url.ordini_backoffice}
      component={OrdiniBackPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.dettaglioordine}
      component={OrdineDettaglioPage}
    />
    <PrivateRoute exact={true} path={Url.resi} component={ResiPage} />
    <PrivateRoute exact={true} path={Url.brands} component={BrandsPage} />
    <PrivateRoute
      exact={true}
      path={Url.nuovobrand}
      component={NuovoBrandPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.modificabrand}
      component={ModificaBrandPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.resi_backoffice}
      component={ResiBackPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.dettaglioreso}
      component={ResoDettaglioPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.statistiche}
      component={StatistichePage}
    />
    <PrivateRoute
      exact={true}
      path={Url.app_socialcommerce}
      component={AppSocialCommercePage}
    />
    <PrivateRoute
      exact={true}
      path={Url.app_socialcommerce_preferenze}
      component={PreferenzePage}
    />
    <PrivateRoute
      exact={true}
      path={Url.app_socialcommerce_backoffice}
      component={AppSocialCommerceBackPage}
    />
    <PrivateRoute exact={true} path={Url.prodotto} component={ProdottoPage} />
    <PrivateRoute
      exact={true}
      path={Url.prodottofederato}
      component={ProdottoPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.prodottoscheda}
      component={ProdottoSchedaPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.prodottofederatoscheda}
      component={ProdottoSchedaPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.magazzinofederato}
      component={MagazzinoFederatoPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.magazzinofederato_backoffice}
      component={MagazzinoFederatoBackPage}
    />
    <PrivateRoute exact={true} path={Url.notifiche} component={NotifichePage} />
    <PrivateRoute
      exact={true}
      path={Url.vedinotifica}
      component={NotifichePage}
    />
    <PrivateRoute exact={true} path={Url.help} component={HelpPage} />
    <PrivateRoute
      exact={true}
      path={Url.techsupport}
      component={TechSupportPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.nuovoticket}
      component={NuovoTicketPage}
    />
    <PrivateRoute
      exact={true}
      path={Url.dettaglioticket}
      component={DettaglioTicketPage}
    />
    <PublicRoute component={NotFoundPage} />
  </Switch>
);

export default Routes;
