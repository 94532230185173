import React, { useState, useCallback, useContext } from "react";
import { Menu as menus } from "routes/menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Collapse from "@material-ui/core/Collapse";
import Icon from "components/buttons/Icon";
import MenuIcon from "./components/MenuIcon";
import MenuHeader from "./components/MenuHeader";
import PopperMenu from "./components/PopperMenu";
import NestedMenu from "./components/NestedMenu";
import styled from "styled-components";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Line, StyledMenuList, StyledMenuListItem } from "./SideMenu.styled";
import { useRights } from "routes/roles";
import { AuthContext } from "contexts/AuthContext";

type Props = {
  onClick: (url: string) => void;
  setExpand: (expand: boolean) => void;
  expand: boolean;
  setOpenMenu: (open: boolean) => void;
  openMenu: boolean;
  currentPath: string;
};


const SideMenu = ({
  onClick,
  expand,
  setExpand,
  setOpenMenu,
  openMenu,
  currentPath,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMenu, setSelectedMenu] = useState<number>();
  const hasRight = useRights();
  const { addFiltriMagazzino } = useContext(AuthContext); 

  const handleClick = useCallback(
    (url?: string, index?: number) => (event?: any) => {
      if (url) {
        setAnchorEl(null);
        setSelectedMenu(undefined);
        addFiltriMagazzino({});
        onClick(url);

        //setExpand(false); 
      } else if (index !== undefined && selectedMenu !== index) {
        setSelectedMenu(index);
        if (!expand && event) {
          setAnchorEl(event.currentTarget);
        }
      } else {
        setAnchorEl(null);
        setSelectedMenu(undefined);
      }
    },
    [selectedMenu, onClick, setExpand, expand]
  );

  return (
    <ClickAwayListener onClickAway={handleClick()}>
      <div key="side-menu-container">
        <StyledMenuList key="side-menu" subheader={<MenuHeader expand={expand} />}>
          {menus.filter(m => !m.rights || hasRight(...m.rights)).map((m, index) => {
            const isSelected = (m.section.indexOf(currentPath) >= 0) ? "selectedMenu" : "";
            return (
              <>
                <StyledMenuListItem
                  button
                  key={"main" + m.label}
                  onClick={handleClick(m.url, index)}
                  className={isSelected}                  
                >
                  <ListItemAvatar>
                    <MenuIcon
                      index={index}
                      selectedMenu={selectedMenu}
                    >
                      <Icon src={m.icon} />
                    </MenuIcon>
                  </ListItemAvatar>
                  <ListItemText primary={m.label} />
                  {/* {enableDownArrow && (
                    <Icon height="8px" src="/images/icons/up.svg" />
                  )}
                  {enableUpArrow && (
                    <Icon height="8px" src="/images/icons/bottom.svg" />
                  )} */}
                </StyledMenuListItem>
                {expand && m.submenu && (
                  <Collapse
                    key={"collapse" + m.label}
                    in={index === selectedMenu}
                    timeout="auto"
                    unmountOnExit
                  >
                    <NestedMenu
                      key="nested-menu"
                      submenu={m.submenu.filter(m => !m.rights || hasRight(...m.rights))}
                      handleClick={handleClick}
                      selectedMenu={selectedMenu}
                    />
                  </Collapse>
                )}
                <Line key="sidemenu-line" />
              </>
            );
          })}
        </StyledMenuList>

        {!expand &&
          selectedMenu !== undefined &&
          menus[selectedMenu].submenu && (
            <PopperMenu
              key="popper-side-menu"
              menu={menus[selectedMenu].submenu!.filter(m => !m.rights || hasRight(...m.rights))}
              anchorEl={anchorEl}
              handleClick={handleClick}
            />
          )}
      </div>
    </ClickAwayListener>
  );
};

export default SideMenu;
