import { Account, Paginazione } from "types/api";
import { parseJwt } from "utilty";

function getAuthTokenParsed<TOKEN>(): TOKEN | null {
    const token =  localStorage.getItem("jwt_auth");
    if(token){
        return parseJwt(token)
    }
    return null;
}

function getAccount() {
    try{
        const json = localStorage.getItem("logged_account");
        if(json){
            return JSON.parse(json) as Account;
        }
    } catch { }

    return null;
}

// function getFiltriMagazzino() {
//     try{
//         const json = localStorage.getItem("filtriMagazzino");
//         if(json){
//             return JSON.parse(json) as Paginazione;
//         }
//     } catch { }

//     return null;
// }


function clear(){
    localStorage.removeItem("jwt_auth");
    localStorage.removeItem("logged_account");
    sessionStorage.removeItem("sessionId");
    //localStorage.removeItem("filtriMagazzino"); 
}

export const storage = {
    getAuthToken: () => localStorage.getItem("jwt_auth"),
    getAccount,
   // getFiltriMagazzino,
   // setFiltriMagazzino: (filtri:Paginazione) => localStorage.setItem("filtriMagazzino", JSON.stringify(filtri)),
    setAuthToken: (token: string) => localStorage.setItem("jwt_auth", token),
    setAccount: (account: Account) => localStorage.setItem("logged_account", JSON.stringify(account)),
    clear,
    getAuthTokenParsed,
}
 