
const BreadcrumbNameMap: Record<string, string> = {
    "/": "Il mio magazzino",
    "/magazzinofederato": "Magazzino Federato",
    "/ordini": "Ordini",
    "/ordini/dettaglioordine": "Dettaglio Ordine",
    "/resi": "Resi",
    "/resi/dettaglioreso": "Dettaglio Reso",
  };  
  

  export function getBreads(pathname: string){
    const segments = pathname.replace("//d.*/", "").split("/").filter(x => x);
    let breads = [];
    //console.log("---> segments",segments);
  
    if(segments.length === 0){
      breads.push("Home");
    } else {
      for(let i=0; i<segments.length; i++){
        const to = `/${segments.slice(0, i + 1).join("/")}`;
        const bread = BreadcrumbNameMap[to];
        // console.log("---> to",to);
        // console.log("---> breadz",bread);
        if(bread){
          breads.push(bread);
        }
      }
    }
    return breads;
  }
  
     
  export function getBreadsMagazzino(pathname: string){
    const segments = pathname.replace("//d.*/", "").split("/").filter(x => x);
    let breads = [];

    if(segments[0]== "magazzinofederato")
      breads.push("Magazzino Federato");
    else
      breads.push("Il mio magazzino");

      for(let i=0; i<segments.length; i++){     
        if(segments[i]== "prodotto")
          breads.push("nomedelbrand");
        if(segments[i]== "prodotto-scheda"){
           breads.push("nomedelbrand");
           breads.push("Scheda prodotto");
        }
        
     }


    return breads;
  }