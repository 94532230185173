import React from "react";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";

const StyledPopper = styled(Popper)`
  width: 200px;
  z-index: ${p => p.theme.zIndex.drawer};
  & .MuiButtonBase-root {
    font-size: 1rem;
  }
  & .MuiList-root {
    padding: 0px;
  }
`;

const StylePopperItem = styled(MenuItem)`
  background-color: #3b444b;
  color: #acafb3;
  padding: 16px;
  &:hover {
    background-color: #171C1C;
  }
`;
export type PopperMenuType = {
  label: string;
  url: string;
};

type Props = {
    menu: PopperMenuType[];
    anchorEl: HTMLElement | null;
    handleClick: (url?: string) => (event?: any) => void;

  };
  
  const PopperMenu = ({
    menu,
    anchorEl,
    handleClick
  }: Props) => (
    <StyledPopper
      placement="left-start"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      role={undefined}
      transition
    >
      {({ TransitionProps, placement }) => (
        <Fade {...TransitionProps} timeout={200}>
          <Paper elevation={0}>
            
              <MenuList autoFocusItem={Boolean(anchorEl)} id="menu-list-grow">
                {menu.map((sm, index) => (
                  <StylePopperItem key={sm.url} onClick={handleClick(sm.url)}>{sm.label}</StylePopperItem>
                ))}
              </MenuList>
           
          </Paper>
        </Fade>
      )}
    </StyledPopper>
  );

export default PopperMenu;