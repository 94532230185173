import React, { useContext, useEffect } from "react";
import "App.scss";
import Icon from "components/buttons/Icon";
import { AuthContext } from "contexts/AuthContext";
import Toolbar from "@material-ui/core/Toolbar";
import { LayoutContext } from "contexts/LayoutContext";
import { history } from "App";
import { Url } from "routes";
import SearchField from "components/fields/SearchField";
import { matchPath, useLocation } from "react-router-dom";
import {
  NotifIconButton,
  NotifIconDot,
  LogoRetailer,
  Space,
  StyledAppBar,
  StyledTypography,
  Row,
  Column,
  StyledIcon,
  StyledPopper,
  StyledAppBarSecondary,
  CartIconButton,
} from "./HeaderContent.styled";
import { GlobalFilter } from "components/filters";
import { Typography } from "components/text/Typography";
import AnteprimaNotifiche from "pages/notifiche/AnteprimaNotifiche";
import { useGet, useGetMutation } from "services/hooks";
import { CartContext } from "contexts/CartContext";

const Notifica = ({ ore, testo }: { ore: string; testo: string }) => (
  <Row>
    <Typography color="white" fontWeight={600}>
      {ore}
    </Typography>
    <Typography color="white">{testo}</Typography>
  </Row>
);

const Profilo = ({
  name,
  surname,
  retailerName,
  onClick,
}: {
  name?: string;
  onClick: () => void;
  surname?: string;
  retailerName?: string;
}) => {
  return (
    <Column onClick={onClick}>
      <Typography
        fontWeight={600}
        color="black"
        classes={{ root: "header_username" }}
      >
        {name} {surname}
      </Typography>
      <Typography
        color="black"
        fontWeight={400}
        fontSize={"0.8rem"}
        classes={{ root: "pointer" }}
      >
        {retailerName}
      </Typography>
    </Column>
  );
};

const HeaderContent = () => {
  const { pathname } = useLocation();
  const { setExpandMenu } = useContext(LayoutContext);
  const { setIsDrawerOpen } = useContext(CartContext);
  const { account, setTotNotifiche, nuoveNotifiche } = useContext(AuthContext);
  const isMagazzinoFederato = matchPath(pathname, {
    path: Url.magazzinofederato,
  });
  // const [sidePanel, setSidePanel] = React.useState<string | null>(null);
  // const handleCloseSidePanel = () => setSidePanel(null);
  //const openSidePanel = (name: string) => () => setSidePanel(name);
  //const nomeutente = account?.name;
  const [totNotifiche] = useGetMutation<any, any>(
    `Notifications/UserNotificationCount`
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleProfilo = () => {
    if (account?.diritti == "Admin" || account?.diritti == "SubAdmin") {
      history.push(Url.profilobackoffice);
    } else {
      history.push(Url.profilo);
    }
  };

  const impostaTotNotifiche = async () => {
    await totNotifiche({ throwOnError: true })
      .then((dati) => {
        setTotNotifiche(dati);
      })
      .catch((error) => {});
  };

  const open = Boolean(anchorEl);

  let timernotifiche = account?.timernotifiche;
  if (!!timernotifiche) timernotifiche = timernotifiche * 60000;
  else timernotifiche = 600000;

  useEffect(() => {
    const timer = setInterval(() => {
      impostaTotNotifiche();
    }, timernotifiche);
    // clearing interval return () => clearInterval(timer);
  });

  useEffect(() => {
    // console.log("Imposta totnotifiche popup");
    impostaTotNotifiche();
  }, [open]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const id = open ? "simple-popper" : undefined;

  return (
    <>
      <StyledAppBar elevation={0} position="sticky">
        <Toolbar>
          <StyledIcon
            button
            onClick={() => setExpandMenu((prev) => !prev)}
            src="/images/icons/menu.svg"
          />
          {/* <Notifica ore="Ore 10:30" testo="Assistenza tecnica via hololens" /> */}
          <SearchField />
          <Space />
          {isMagazzinoFederato && (
            <CartIconButton
              button
              src="/images/icons/carrello.svg"
              aria-describedby={id}
              onClick={() => setIsDrawerOpen(true)}
            />
          )}
          <NotifIconDot>{nuoveNotifiche}</NotifIconDot>
          <NotifIconButton
            button
            src="/images/icons/notification.svg"
            aria-describedby={id}
            onClick={handleClick}
          />
          <StyledPopper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
          >
            <AnteprimaNotifiche onClose={handleClick} />
          </StyledPopper>
          <Profilo
            name={account?.name}
            surname={account?.surname}
            retailerName={account?.retailerTopbar}
            onClick={() => handleProfilo()}
          />
          <LogoRetailer
            button
            src={account?.logo ? account?.logo : "/images/logo_placeholder.png"}
            onClick={() => handleProfilo()}
          />
        </Toolbar>
      </StyledAppBar>
    </>
  );
};

export default HeaderContent;
