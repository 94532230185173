import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import styled from "styled-components";

export const StyledMenuList = styled(List)`
  & .MuiListItemText-primary {
    font-size: 0.86rem;
  }
  & .selectedMenu {
    border-left:0.375rem solid #765EB5; 
    background-color: rgba(255, 255, 255, 0.25);
    padding-left: 0.58rem;
  }
  padding-bottom: 0px;
  
  & .MuiListItem-button:hover { background-color: rgba(255, 255, 255, 0.25); }  
`;

export const StyledMenuListItem = styled(ListItem)`
  padding-bottom: 7px;
  padding-top: 7px;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  & .MuiListItemAvatar-root {
      min-width: 56px;
      flex-shrink: 0;
      justify-content: center;
      display: flex;
  }
`;

export const Line = styled.div`
  height: 1px;
  background: white;
  margin-top: 0px;
  opacity: 0.1;
`;
