import React, { ReactNode } from "react";
import { storage } from "services";
import { Account, Token, Paginazione } from "types/api";
import { queryCache } from "react-query";
import { goTo, Url } from "routes";
import { parseJwt } from "utilty";

export type AuthContextModel = {
  account: Account | null;
  filtrimagazzino: object;
  nuoveNotifiche: number;
  addFiltriMagazzino: (filtri: object) => void;
  setTotNotifiche: (num: number) => void;
  login: (account: Account) => void;
  logout: Function;
  isAuthenticated: () => boolean;
  setTimeZone: (timeZone: string) => void;
  updateAccount: (name: string, surname: string, logo?: string) => void;
};

export const initialAuthContext: AuthContextModel = {
  account: null,
  filtrimagazzino: {},
  nuoveNotifiche: 0,
  addFiltriMagazzino: (object: Paginazione) => {},
  setTotNotifiche: (num: number) => 0,
  login: (account: Account) => {},
  logout: () => {},
  isAuthenticated: () => false,
  setTimeZone: (timeZone: string) => {},
  updateAccount: (name: string, surname: string) => {},
};

export const AuthContext =
  React.createContext<AuthContextModel>(initialAuthContext);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [account, setAccount] = React.useState<Account | null>(
    storage.getAccount()
  );
  console.log("account:", account);
  // const [filtrimagazzino,setFiltriMagazzino] = React.useState<Paginazione | any>(storage.getFiltriMagazzino());
  const [filtrimagazzino, setFiltriMagazzino] = React.useState<
    Paginazione | any
  >({});
  const [nuoveNotifiche, setNuoveNotifiche] = React.useState<number>(0);

  const addFiltriMagazzino = (filtri: Paginazione) => {
    //storage.setFiltriMagazzino(filtri);
    setFiltriMagazzino(filtri);
  };

  const setTotNotifiche = (num: number) => {
    setNuoveNotifiche(num);
  };

  const login = (accountData: Account) => {
    const tokenParam = storage.getAuthToken();
    let token = parseJwt<Token>(tokenParam!);
    // console.log("Token: ",token);
    // console.log("accountData: ",accountData);
    accountData.diritti = token.urole;
    accountData.timernotifiche = token.notificationtimer;
    accountData.retailerTopbar = token.retailername;
    accountData.tokenSYNC = token.apitoken;
    accountData.retailerid = token.retailerid;
    // console.log("account.surname="+account?.surname+" - account.name="+account?.name);
    // if((!account) || (!account.surname) || (!account.name) ){
    //    console.log("Account vuoto");
    accountData.surname = token.surname;
    accountData.name = token.name;
    accountData.userId = token.uid;
    // }

    if (!!token.logo) accountData.logo = token.logo;

    storage.setAccount(accountData);
    setAccount(accountData);
  };

  const logout = () => {
    storage.clear();
    queryCache.clear();
    setAccount(null);
    goTo(Url.login);
  };
  const isAuthenticated = () => {
    return !!storage.getAuthToken();
  };

  const setTimeZone = (timeZone: string) => {
    if (account) {
      const updated = { ...account, fusoOrario: timeZone };
      storage.setAccount(updated);
      setAccount(updated);
    }
  };

  const updateAccount = (name: string, surname: string, logo?: string) => {
    if (account) {
      const updated = { ...account };
      updated.name = name;
      updated.surname = surname;
      if (!!logo) updated.logo = logo;
      storage.setAccount(updated);
      setAccount(updated);
      console.log("account updated:", updated);
    }
  };

  const authContextValue: AuthContextModel = {
    account,
    filtrimagazzino,
    addFiltriMagazzino,
    setTotNotifiche,
    nuoveNotifiche,
    login,
    logout,
    isAuthenticated,
    setTimeZone,
    updateAccount,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
