import { utils, writeFile } from "xlsx";
import { DateTime } from "luxon";

export const exportXLSX = async (data: any[], fileName: string = "imesa", bookName: string = "Dati") => {
  if (data) {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, bookName);
    writeFile(wb, fileName+".xlsx");
  }
};

export const getParam = (param: string) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get(param);
}

export function parseJwt<T = any>(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  
  return JSON.parse(jsonPayload) as T;
};


export const formatDate = (date: any) => DateTime.fromJSDate(new Date(date)).toFormat("dd/MM/yyyy");
export const formatDatetoHour = (date: any) => DateTime.fromJSDate(new Date(date)).toFormat("HH:mm");
export const formatToDateTime = (date: any) => DateTime.fromJSDate(new Date(date)).toFormat("dd/MM/yyyy HH:mm");
export const now = () => DateTime.local().toJSDate()
export const formatDateDash = (date: any) => DateTime.fromJSDate(new Date(date)).toFormat("yyyy-MM-dd");
