import React from "react";
import { useTranslation } from "react-i18next";

const UnauthorizedPage = () => {
  const { t } = useTranslation();

  return <div>{t("Not Authorized")}</div>;
};

export default UnauthorizedPage;
