import styled, { css } from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import { Typography } from "components/text/Typography";
import Breadcrumbs, { BreadcrumbsProps } from "@material-ui/core/Breadcrumbs";
import { useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { getBreads } from "routes";
import Icon from "components/buttons/Icon";
import Popper from "@material-ui/core/Popper"; 

export const Space = styled.div`
  flex-grow: 1;
`;

const cssAppBar = css`
  z-index: ${(p) => p.theme.zIndex.drawer - 1};
  color: black;
  background-color: #fff;
  height:90px;
  padding-top: 0.55rem;
  ${(p) => p.theme.breakpoints.down("xs")} {
    z-index: ${(p) => p.theme.zIndex.drawer + 1};
  }
`;

export const StyledAppBar = styled(AppBar)`
  ${cssAppBar}
`;

export const StyledAppBarSecondary = styled(AppBar)`
    ${cssAppBar}
    z-index: ${(p) => p.theme.zIndex.drawer - 2};
`;

export const CartIconButton = styled(Icon)`
  height: 20px;
  width: 30px;
  margin-right: 16px;
`;

export const NotifIconButton = styled(Icon)`
  height: 20px;
  width: 30px;
  margin-right: 16px;
`;

export const NotifIconDot = styled.div`
  height: 1.1rem;
  width: 1.1rem;
  color: #fff;
  font-size: 0.75rem;
  border-radius: 1rem;
  padding: 0.1rem;
  background: #0BC931;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -2rem;
  margin-top: -1.2rem;
`;

export const LogoRetailer = styled(Icon)`
    height: 47px;
    width: 47px;
    overflow: hidden;
    margin-left: 16px;
    border-radius: 47px;
`;

export const StyledTypography = styled(Typography)`
  ${(p) => p.theme.breakpoints.down("xs")} {
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledIcon = styled(Icon)`
  margin: 8px;
`;

export const StyledPopper = styled(Popper)`
 
 margin-top: 1rem;
    margin-left: 0.4rem;
 width: 18.6rem;
 max-width: 50vw;
 height: 23.1rem;
 max-height:80vh;
 overflow: hidden;
 z-index:1200;
 background:#fff;
 box-shadow: 0rem 0.4rem 0.55rem -0.18rem #aaa;

 & > div{
  padding:0;
  border-radius:0.3rem;
  font-size:0.83rem;
 }
 & > div::before{
  content:"  ";
  display: inline-block;
  width:0;
  height: 0px;
  border-style:solid;
  border-width:8px 16px 8px 0;
  border-color:transparent #EEEAF5 transparent transparent;
  margin-top:-1.3rem;
  top:0.1rem;
  position: relative;
 }

`;
