import React, { useState, useContext } from "react";
import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SideMenu from "./SideMenu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AuthContext } from "contexts/AuthContext";
import {Logout} from "./components/Logout";
import {drawerWidth} from "template/MasterPage";
import { LayoutContext } from "contexts/LayoutContext";

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    background-color: #1e2a32;
    color: #c1c2c5;
  }
  & .makeStyles-drawerClose-3 {
    @media (max-height: 756px){   
      width:81px; 
      /* overflow: hidden;
      overflow-x: hidden;
      overflow-y: auto; */
    }
  }

`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      },
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      },
      // '@media (max-height: 758px)' : {
      //   width: theme.spacing(10) + 1
      // }

    }
  })
);

type Props = {
  onClick: (url: string) => void;
  currentPath: string;
};

const MenuDrawer = ({ onClick, currentPath }: Props) => {

  const classes = useStyles();
  
  const {openMenu, setOpenMenu, expandMenu, setExpandMenu} = useContext(LayoutContext);
  
  const mobile = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));

  const wide = expandMenu || mobile;

  return (
    <StyledDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: wide,
        [classes.drawerClose]: !wide,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: wide,
          [classes.drawerClose]: !wide,
        }),
      }}
      open={openMenu}
      onClose={() => setOpenMenu(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <SideMenu
        expand={wide}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        setExpand={setExpandMenu}
        onClick={onClick}
        currentPath={currentPath}
      />
      <Logout />
    </StyledDrawer>
  );
};

export default MenuDrawer;