import { fetcherSYNC, fetcher } from "./fetcher";
import {storage} from "./storage";
import {
  useDelete,
  useGet,
  usePaginatedGet,
  usePut,
  usePost,
  useGetMutation,
  useInfiniteGet,
  prefetch,
} from "./hooks";


export {
  useDelete,
  useGet,
  usePaginatedGet,
  usePut,
  usePost,
  useGetMutation,
  useInfiniteGet,
  prefetch,
  fetcher,
  fetcherSYNC,
  storage,
};
