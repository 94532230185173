import React, { useEffect } from "react";
import { Row, Column } from "react-table";
import {
  initialPage,
  useQueryTableServer,
  useServerTable,
} from "hooks/use-table-server-pagination";
import Table from "components/table/Table";
import { PaginatedResponse, PaginatedRequest } from "types/api";
import { goTo, Url } from "routes";
import { exportXLSX } from "utilty";
import { useGetMutation } from "services/hooks";
import { PageLoading } from "template/PageLoading";

type Request = PaginatedRequest & Record<string, any>;

type Props<T extends object = {}> = {
  tableUrl: string;
  columns: Column<T>[];
  rowUrl?: string;
  idName: string;
  hideColumnId?: boolean;
  filter?: Partial<Request>;
  onOpen?: (row: Row<T>) => any;
  refresh?: any;
  savePagination?: (pagesize: any) => void;
};

export function PageTable<RowType extends object = {}>({
  tableUrl,
  columns,
  idName = "id",
  hideColumnId = true,
  rowUrl,
  filter,
  onOpen,
  refresh,
  savePagination,
}: Props<RowType>) {
  // console.log("initialPage:",initialPage);
  // console.log("filter:",filter);
  const [request, setRequest] = React.useState<Request>(() => ({
    ...initialPage,
    ...filter,
  }));

  React.useEffect(() => {
    setRequest((prev) => ({
      ...prev,
      ...filter,
    }));
  }, [filter]);

  const [downloadAll] = useGetMutation<PaginatedResponse<RowType>, Request>(
    tableUrl
  );

  const onExport = async () => {
    const r = await downloadAll({ ...request, rowsForPage: 0 });
    if (r) {
      await exportXLSX(r.items);
    }
  };

  const {
    rows: documentsData,
    serverPages,
    isFetching,
    totalCount,
    isInitialData,
    refetch,
  } = useQueryTableServer<Request, RowType>(tableUrl, request);

  console.log("DOCUMENTSDATA", documentsData);

  useEffect(() => {
    //console.log('XY REFRESH PAGETABLE');
    refetch();
  }, [refresh]);

  const table = useServerTable<Request, RowType>(
    documentsData,
    serverPages,
    columns,
    setRequest,
    request,
    {
      onOpen: rowUrl
        ? (row) => goTo(rowUrl.replace(":id", row.id))
        : onOpen
        ? (row) => onOpen(row)
        : undefined,
      onExport,
      totalCount,
    },
    idName,
    hideColumnId
  );

  useEffect(() => {
    if (typeof savePagination === "function") {
      savePagination(table.state);
    }
  }, [table.state]);

  console.log("TABLE", table);
  return (
    <div>
      {/* {isFetching && <TopBarProgress />} */}
      {isFetching && isInitialData ? (
        <PageLoading />
      ) : (
        <Table instance={table} />
      )}
    </div>
  );
}
