import React, { useRef, useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useGet } from "services";
import { Account } from "types/api";

type Props = {
  children: JSX.Element;
};

export const AuthenticateOnStart = ({ children }: Props) => {
  const { login, isAuthenticated } = useContext(AuthContext);
  const initialized = useRef(false);
  const isLogged = isAuthenticated();

  const { data, isFetching } = useGet<Account>("UserProfile", null, {
    enabled: isLogged && !initialized.current,
  });

  React.useEffect(() => {
    if (!isFetching && !initialized.current && data) {
      login(data);
      initialized.current = true;
    }
  }, [isFetching, data, login])

  if (!isLogged) {
    initialized.current = true;
  }

  return children;
};
