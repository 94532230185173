import {
  TableInstance,
  Column,
  SortingRule,
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  Row,
  IdType,
  CustomTableProps,
} from "react-table";
import { useSticky } from "react-table-sticky";
import React from "react";
import { PaginatedRequest, PaginatedResponse } from "types/api";
import { AxiosResponse } from "axios";
import { usePaginatedQuery, queryCache } from "react-query";
import { usePaginatedGet, prefetch } from "services";
import {useActions} from "components/table/plugins";
import usePrevious from "./use-previous-value";

export const initialPage: PaginatedRequest = {
  pageNumber: 0,
  rowsForPage: 8,
  orderBy: "id",
  asc: true,
};

export function useQueryTableServer<
  REQUEST extends PaginatedRequest = PaginatedRequest,
  R extends object = {}
>(api: string, request: REQUEST) {
  const { resolvedData, error, isFetching, refetch, isFetchingMore, isFetched, isInitialData } = usePaginatedGet<
    PaginatedResponse<R>,
    any
  >(api, request);

  const serverPages = React.useMemo(
    () => Math.ceil((resolvedData?.totalCount || 0) / request.rowsForPage),
    [resolvedData, request]
  );

  // Prefetch the next page!
  React.useEffect(() => {
    if (serverPages > request.pageNumber + 1) {
      const next = { ...request, pageNumber: request.pageNumber + 1 };
      prefetch(api, next);
    }
  }, [request, serverPages, api]);

  const rows = React.useMemo(() => {
    return resolvedData?.items ?? [];
  }, [resolvedData]);

  const totalCount = resolvedData?.totalCount || 0;

  return {
    rows,
    totalCount,
    serverPages,
    error,
    isFetching,
    refetch,
    isInitialData
  };
}

export function useTableControls<
  REQUEST extends PaginatedRequest = PaginatedRequest,
  R extends object = {}
>(
  table: TableInstance<R>,
  setRequest: React.Dispatch<React.SetStateAction<REQUEST>>,
  rowId: IdType<R>
) {
  const { pageIndex, pageSize, sortBy } = table.state;
  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    setRequest((prev) => {
      if (
        pageSize === prev.rowsForPage &&
        ((sortBy.length &&
          sortBy[0].id === prev.orderBy &&
          !sortBy[0].desc === prev.asc) ||
          (!sortBy.length && !prev.orderBy))
      ) {
        return {
          ...prev,
          pageNumber: pageIndex
        };
      }
      
      return {
        ...prev,
        pageNumber: 0,
        rowsForPage: pageSize,
        orderBy: sortBy.length ? sortBy[0].id : rowId,
        asc: sortBy.length ? !sortBy[0].desc : true,
      };
    });
  }, [pageIndex, pageSize, sortBy, setRequest, rowId]);

}

export function useServerTable<
  REQUEST extends PaginatedRequest = PaginatedRequest,
  R extends object = {}
>(
  data: R[],
  serverPages: number,
  columns: Column<R>[],
  setRequest: React.Dispatch<React.SetStateAction<REQUEST>>,
  request: REQUEST,
  rest: CustomTableProps<R> = {},
  rowId: IdType<R> = "id",
  hideColumnId: any
) {
  
  if(hideColumnId)
    hideColumnId = rowId;
  else
    hideColumnId = ""; 

  const table = useTable<R>(
    {
      columns,
      data,
      getRowId: (original, index, parent) => String((original as any)[rowId]),
      initialState: {
        hiddenColumns: [hideColumnId],
        pageIndex: request.pageNumber,
        sortBy: [{ id: request.orderBy, desc: !request.asc }],
        pageSize: request.rowsForPage      
      },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      manualSortBy: true,
      autoResetPage: false,
      pageCount: serverPages,
      disableSortRemove: true,
      ...rest
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useSticky,
  );
  
  const prevData = usePrevious(data);

  React.useEffect(() => {
    if(prevData!==data){
      table.gotoPage(request.pageNumber);
    }
  }, [prevData, data, request, table]);

  useTableControls(table, setRequest, rowId);

  return table
}
