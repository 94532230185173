import React from "react";
import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from "@material-ui/core/Typography";
import styled from "styled-components";

export type StyledTypographyProps = {
  color?: string;
  fontWeight?: number | string;
  display?: string;
  fontFamily?: string;
  fontSize?: string;
  cursor?: string;
  textDecoration?: string;
  textTransform?: string;
} & Omit<MuiTypographyProps, "color" | "display">;

const StyledTypography = styled(
  ({ color, display, fontWeight, fontFamily, fontSize, cursor, textDecoration, textTransform, ...props }) => (
    <MuiTypography {...props} />
  )
)<StyledTypographyProps>`
  ${(p) => `
  color: ${p.color ?? ""};
  display: ${p.display ?? ""};
  font-weight: ${p.fontWeight ?? ""};
  font-family: ${p.fontFamily ?? ""};
  font-size: ${p.fontSize ?? ""};
  cursor: ${p.cursor ?? ""};
  text-decoration: ${p.textDecoration ?? ""};
  text-transform: ${p.textTransform ?? ""};
`}
`;

export function Typography({ ...props }: StyledTypographyProps) {
  return <StyledTypography {...props} />;
}
