import React from "react";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";

const UnselectedMenuIcon = styled(Avatar)`
  background-color: initial;
`;

const SelectedMenuIcon = styled(Avatar)`
  background-color: initial;
`;

const PathMenuIcon = styled(Avatar)`
  background-color: #c00;
`;

type MenuIconProps = {
  index: number;
  children: JSX.Element;
  selectedMenu?: number;
};

const MenuIcon = ({ index, children, selectedMenu }: MenuIconProps) => {

  return(  <UnselectedMenuIcon>{children}</UnselectedMenuIcon>  );

}
  


export default MenuIcon;
