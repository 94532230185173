import React from "react";
import TableCell from "@material-ui/core/TableCell";
import styled from "styled-components";
import { Cell, TableCellProps } from "react-table";
import Typography from "@material-ui/core/Typography";

export const  CellTypography = styled(Typography)`
  color: black;
  font-size: 0.8125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const StyledTableCell = styled(TableCell)`
  max-width: 150px;
`

type Props<T extends object> = {
  children: React.ReactNode;
  columnId: any;
  className?: string;
} & TableCellProps;

function BodyCell<T extends object>({
  children,
  columnId,
  ...props
}: Props<T>) {
  if (columnId === "selection") {
    return <StyledTableCell {...props}>{children}</StyledTableCell>;
  } else if (columnId === "action") {
    return <StyledTableCell {...props}><ActionContainer>{children}</ActionContainer></StyledTableCell>;
  } else {
    return (
      <StyledTableCell {...props}>
        <CellTypography>{children}</CellTypography>
      </StyledTableCell>
    );
  }
}

export default BodyCell;
