import React, { useContext } from "react";
import { AuthContext } from "contexts";
import {
  LocalizationProvider,
} from "@material-ui/pickers";
import LuxonUtils from '@date-io/luxon';
import { Settings } from "luxon";

export const getBrowserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

type Props = {
  children: React.ReactNode;
  locale?: any;
};

export const DatePickerProvider = ({
  children,
  locale,
}: Props) => {
  const { account } = useContext(AuthContext);

  // React.useEffect(() => {
  //   if(account?.fusoOrario){
  //     Settings.defaultZoneName = account.fusoOrario;
  //   }
  // }, [account])

  return <LocalizationProvider dateAdapter={LuxonUtils} locale={locale} children={children} />;
};
