import React from "react";
import styled, {css} from "styled-components";
import Button from "@material-ui/core/Button";

const shared = css`
    border: 1px solid #bbb8b3;
    border-radius: 0px;
    height: 40px;
    margin-right: -1px;
    text-overflow: clip;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    ${p => p.theme.breakpoints.down("xs")} {
        min-width: auto;
    }
`;

export const PrevButton = styled(Button)`
    ${shared}
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
`; 

export const NextButton = styled(Button)`
    ${shared}
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
`; 

export const PageButton = styled(Button)`
    ${shared}
`; 

export const SelectedPageButton = styled(Button)`
    ${shared}
    background-color: ${p => p.theme.palette.primary.main};
    color:#fff;
    &:hover {
        background-color: ${p => p.theme.palette.primary.main};
    }
`; 