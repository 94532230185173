import React from "react";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import { ColumnInstance } from "react-table";
import ButtonIcon from "components/buttons/Icon";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";

type Props<T extends object> = {
  column: ColumnInstance<T>;
  className?: string;
};

const StyledTableCell = styled(TableCell)`
  display: table-cell;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

const sharedContentStyle = css`
  font-size: 0.8125rem;
  color: #000000;
  margin-right: 0.8125rem;
  display: inline-block;
  font-weight: 800;
`;

const HeaderText = styled(Typography)`
  ${sharedContentStyle}
`;

const HeaderAction = styled.div`
  ${sharedContentStyle}
  float: right;
  margin: 0px;
  display: flex;
`;

function HeaderCell<T extends object>({ column, ...rest }: Props<T>) {
  return column.id === "selection" ||
    column.id === "action" ||
    !column.getSortByToggleProps ? (
    <Cell column={column} {...rest}>
      <Content column={column}>{column.render("Header")}</Content>
    </Cell>
  ) : (
    <SortCell column={column} {...rest}>
      <Content column={column}>{column.render("Header")}</Content>
      <SortArrows column={column} />
    </SortCell>
  );
}

function Content<T extends object>({
  column,
  children,
}: Props<T> & { children: React.ReactNode }) {
  return column.id === "action" ? (
    <HeaderAction>{children}</HeaderAction>
  ) : (
    <HeaderText>{children}</HeaderText>
  );
}

function Cell<T extends object>({
  column,
  ...rest
}: Props<T> & TableCellProps) {
  return (
    <StyledTableCell {...column.getHeaderProps()} {...rest} />
  );
}

function SortCell<T extends object>({
  column,
  ...rest
}: Props<T> & TableCellProps) {
  return (
    <StyledTableCell
      {...column.getHeaderProps(column.getSortByToggleProps())}
      {...rest}
    />
  );
}

function SortArrows<T extends object>({ column }: Props<T>) {
  return column.isSorted && column.isSortedDesc ? (
    <ButtonIcon
      width="14px"
      height="14px"
      src="images/icons/table-order-desc.svg"
    />
  ) : column.isSorted ? (
    <ButtonIcon
      width="14px"
      height="14px"
      src="images/icons/table-order-asc.svg"
    />
  ) : column.canSort ? (
    <ButtonIcon
      width="14px"
      height="14px"
      src="images/icons/table-order-neutral.svg"
    />
  ) : null;
}

export default HeaderCell;
