import axios, { AxiosResponse, AxiosError } from "axios";
import { storage } from "./storage";
import { goTo, Url } from "routes";
import { queryCache } from "react-query";
import { mockApi } from "./mocker";
import MockAdapter from "axios-mock-adapter";


export const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  // headers: {
  //   //csrf: "token",
  //   //"Content-Type": "application/json",
  // },
});

export const fetcherSYNC = axios.create({
  baseURL: process.env.REACT_APP_SYNC_ENDPOINT,
  //baseURL: "https://lfwsyncapi.nautes.eu/api/", 
 // baseURL: "https://lfwsyncapitest.nautes.eu/api/",
  // headers: {
  //   //csrf: "token",
  //   //"Content-Type": "application/json",
  // },
});

/// TODO REMOVE !!! ONLY FOR TEST
//mockApi(fetcher)
// var mock = new MockAdapter(fetcher, {onNoMatch: "passthrough"});
// mock.onGet("/InterventiResoconti/51").reply<InterventoResocontoResponse[]>(200, [
//   {
//     id: 0, 
//     componente: 101,
//     tipologia: "INSPEZIONE_VISIVA",
//     esito: "PROBLEMA_RISOLTO_COMPLETAMENTE",
//     codice: "51-12345",
//     ultimaModifica: new Date("2020-07-27T09:53:14.825Z"),
//     modificatoDa: "Mario",
//     note: "Un quadro si era rotto ma poi l'ho riparato"
//   },
//   {
//     id: 1, 
//     componente: 102,
//     tipologia: "CONTROLLO_APPROFONDITO",
//     esito: "PROBLEMA_RISOLTO_PARZIALMENTE",
//     codice: "52-12345",
//     ultimaModifica: new Date("2020-07-27T09:53:14.825Z"),
//     modificatoDa: "Giovanni",
//     note: "Non funziona più niente"
//   },
// ]);

function handleSuccess(response: AxiosResponse<any>) {
  if(response.headers){
      const token = response.headers["token"];
      if (token) {
        storage.setAuthToken(token);
      }
  }

  log(response);


  // if(response.data?.token === TokenResult.NoToken){
  //   storage.clearAuthToken();
  //   history.push("/login");
  // }

  return response;
}

function log(response: AxiosResponse<any>, style: string = "color: #03f42f") {
  console.groupCollapsed(
    `%cHTTP ${response.config.method} ${response.statusText}: ${response.config.url}`,
    style
  );

  console.groupCollapsed("request");
  console.debug("params", response.config.params);
  console.debug("body", response.config.data);
  console.debug("headers", response.config.headers);
  console.debug("baseUrl", response.config.baseURL);
  console.groupEnd();

  console.groupCollapsed("response");
  console.debug("body", response.data);
  console.debug("headers", response.headers);
  console.groupEnd();

  console.groupEnd();
}

function handleError(error: AxiosError) {
  const response = error.response;
  if(response){
    log(response, "color: #f40303");
    switch (response.status) {
      case 401:
        let currentUrl = window.location.pathname;
        if(currentUrl!==Url.login){
          storage.clear();
          queryCache.clear();
          goTo(Url.login + "?returnUrl=" + currentUrl);
        }
        break;
    }
  }
  return Promise.reject(error);
}

fetcher.interceptors.request.use(
  (config) => {
    const token = storage.getAuthToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

fetcher.interceptors.response.use(handleSuccess, handleError);

fetcherSYNC.interceptors.request.use(
  (config) => {
    // const token = storage.getAuthToken();
    // if (token) {
    //   config.headers["Authorization"] = "Bearer " + token;
    // }
    //primo e unico header
   config.headers["Content-Type"] = "application/json"; 
   
  // config.headers["Content-Type"] = "application/json-patch+json";
  //  config.headers["Sec-Fetch-Dest"] = "empty"; 
  //  config.headers["Sec-Fetch-Site"] = "cors"; 
  //  config.headers["Sec-Fetch-Mode"] = "cross-site";  
  //  config.headers["Access-Control-Allow-Methods"] = "GET, PUT, POST, DELETE, HEAD, OPTIONS";
  //  config.headers["Access-Control-Allow-Origin"] = "http://localhost:3000";
  //  config.headers["Access-Control-Allow-Headers"] = "accept, cache-control, credentials, content-type, authorization, origin, X-Requested-With, X-dev-mode";
    // config.headers["Accept"] = "*/*";

    // config.headers["Access-Control-Allow-Methods"] = "GET, POST, PATCH, PUT, DELETE, OPTIONS";
    // config.headers["Access-Control-Allow-Headers"] = "Origin, Content-Type, X-Auth-Token";

    // config.headers["Access-Control-Allow-Origin"] = "*";
    // config.headers["Access-Control-Allow-Methods"] = "GET, POST, PATCH, PUT, DELETE, OPTIONS";
    //config.headers["Access-Control-Allow-Headers"] = "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With";
    //config.headers["Referrer-Policy"] = "no-referrer-when-downgrade";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

fetcherSYNC.interceptors.response.use(handleSuccess, handleError);

