import { ReactQueryConfig } from "react-query";

export const queryConfig: ReactQueryConfig = {
  shared: {
    suspense: false,
  },
  mutations: {
    useErrorBoundary: undefined, // Defaults to the value of `suspense` if not defined otherwise
    throwOnError: false,
  },
  queries: {
    useErrorBoundary: undefined, // Defaults to the value of `suspense` if not defined otherwise
    refetchOnWindowFocus: false,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    staleTime: 0,
    cacheTime: 5 * 60 * 1000,
    refetchInterval: false,
    //queryFnParamsFilter: args => filteredArgs,
    refetchOnMount: true,
  },
};
