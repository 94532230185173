import React from "react";
import Helmet from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import LeftContent from "template/LeftContent";
import HeaderContent from "template/HeaderContent";
import BodyContent from "template/BodyContent";
import { useLocation } from "react-router-dom";
import { getBreads } from "routes";
import { Root, ToolbarSpace, MainContent, BodyContainer } from "./MasterPage.styled";
import {FooterContent} from "./FooterContent";
import {PageLoading} from "./PageLoading";
export const drawerWidth = 300;

export type MasterPageLayoutProps = {
  drawer?: boolean;
  header?: boolean;
  footer?: boolean;
};

type Props = {
  children: JSX.Element;
} & MasterPageLayoutProps;

const MasterPage = ({ children, drawer = true, header = true, footer = true }: Props) => {
  const location = useLocation();
  const title =
    "Luxury Federated Warehouse – " + getBreads(location.pathname).join(" – ");
  return (
    <Root>
      <CssBaseline />
      <Helmet>
        <html lang="it" />
        <title>{title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <meta http-equiv="content-type" content="text/html; charset=UTF-8" />        
        <meta property="og:title" content="Luxury Federated Warehouse" />
        <meta
          property="og:image"
          content="https://lfwretailertest.nautes.eu:443null"
        />
        <meta property="og:type" content="website" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      </Helmet>

      {drawer && <LeftContent />}

      <MainContent>
        {header && <HeaderContent />}
        {/* {header && <ToolbarSpace />} */}
        <BodyContainer id="#content">
          <BodyContent>{children}</BodyContent>
        </BodyContainer>
        {footer && <FooterContent/>}
      </MainContent>
    </Root>
  );
};

export default MasterPage;
