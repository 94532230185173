import React from "react";
import classes from "./LinkButton.module.scss";

type Props = {
  text: string;
  onClick?: () => void;
  className?: string;
};

const LinkButton = ({ text, className, onClick  }: Props) => {
  return (
    <span onClick={onClick}  className={`${classes.linkbutton} ${className}`}>
      {text}
    </span>
  );
};
export default LinkButton;
