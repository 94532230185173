import { Url } from "routes";

export type SubmenuType = {
  label: string;
  url: string;
  rights?: string[];
};

export type MenuType = {
  label: string;
  url?: string;
  icon: string;
  submenu?: SubmenuType[];
  section: string[];
  rights?: string[];
};

export const Menu: MenuType[] = [
  {
    label: "Società Federate",
    url:  Url.societafederate,
    section: ["societafederate"],
    icon: "/images/icons/societafederate.svg",
    rights: ["Admin"] 
  },
  {
    label: "Il Mio Magazzino",
    url: Url.home,
    section: ["","prodotto","prodotto-scheda"],
    icon: "/images/icons/il-mio-magazzino.svg",
    rights: ["Retailer","Collaborator"]
  },
  {
    label: "Magazzino Federato",
    url:  Url.magazzinofederato,
    section: ["magazzinofederato"],
    icon: "/images/icons/magazzino-federato.svg",
    rights: ["Retailer","Collaborator"]
  },
  {
    label: "Magazzino Federato",
    url:  Url.magazzinofederato_backoffice,
    section: ["magazzinofederato"],
    icon: "/images/icons/magazzino-federato.svg",
    rights: ["Admin","SubAdmin"]
  },
  {
    label: "Brands",
    url: Url.brands,
    section: ["brands"],
    icon: "/images/icons/brands.svg",
//    rights: ["Admin"]
  },
  // {
  //   label: "Scalarini Taglie",
  //   url: Url.home,
  //   section: ["scalarini-taglie"],
  //   icon: "/images/icons/scalarinitaglie.svg",
  //   rights: ["Admin"] 
  // },
  { 
    label: "Ordini",
    url: Url.ordini,
    section: ["ordini"],
    icon: "/images/icons/ordini.svg",
    rights: ["Retailer","Collaborator"]
  },
  { 
    label: "Ordini",
    url: Url.ordini_backoffice,
    section: ["ordini"],
    icon: "/images/icons/ordini.svg",
    rights: ["Admin","SubAdmin"]
  },
  {
    label: "Resi",
    url: Url.resi,
    section: ["resi"],
    icon: "/images/icons/resi.svg", 
    rights: ["Retailer","Collaborator"]
  }, 
  {
    label: "Resi",
    url: Url.resi_backoffice,
    section: ["resi"],
    icon: "/images/icons/resi.svg", 
    rights: ["Admin","SubAdmin"]
  }, 
  {
    label: "Fatturazione",
    url: Url.home,
    section: ["fatturazione"],
    icon: "/images/icons/fatturazione.svg",
    rights: ["Admin","SubAdmin"]
  },
  // {
  //   label: "Statistiche",
  //   url: Url.statistiche,
  //   section: ["statistiche"],
  //   icon: "/images/icons/statistiche.svg",
  //   rights: ["Retailer","Admin"]
  // },
  // {
  //   label: "Customer Care",
  //   url: Url.home,
  //   section: ["customer-care"],
  //   icon: "/images/icons/customer-care.svg",
  // },
  {
    label: "Tech support",
    url: Url.techsupport,
    section: ["statistiche"],
    icon: "/images/icons/techsupport.svg",
    rights: ["Admin","SubAdmin"]
  },
  /* {
    label: "Help",
    url: Url.help,
    section: ["help"],
    icon: "/images/icons/help.svg",
    rights: ["Retailer","Collaborator"]
  }, */
  {
    label: "Utenti",
    url: Url.Configurazione.Utenti.gestione,
    section: ["configurazione"],
    icon: "/images/icons/utenti.svg",
    rights: ["Retailer"]
  },
  {
    label: "Utenti",
    url: Url.utentibackoffice,
    section: ["utentibackoffice"],
    icon: "/images/icons/utenti.svg",
    rights: ["Admin"]
  },
  // {
  //   label: "App social commerce",
  //   url: Url.app_socialcommerce,
  //   section: ["app_socialcommerce"],
  //   icon: "/images/icons/app_socialcommerce.svg",
  //   rights: ["Retailer","Collaborator"]
  // },
  // {
  //   label: "App social commerce",
  //   url: Url.app_socialcommerce_backoffice,
  //   section: ["app_socialcommerce"],
  //   icon: "/images/icons/app_socialcommerce.svg",
  //   rights: ["Admin","SubAdmin"]
  // },

  
];
