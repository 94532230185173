import React from "react";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { TableInstance, Row } from "react-table";
import HeaderCell from "./components/HeaderCell";
import BodyCell from "./components/BodyCell";
import { HeaderRow, BodyRow } from "./components/rows";
import styled from "styled-components";
import Pagination from "./components/pagination/Pagination";
import { Footer } from "./components/Footer";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export type SubComponentProps<T extends object = {}> = { row: Row<T> };

type Props<T extends object = {}> = {
  instance: TableInstance<T>;
};

const StyledTableContainer = styled(TableContainer)``;

const StyledTable = styled(MuiTable)`
  thead td {
    background-color: #d2d4d5;
  }
  tbody td {
    background-color: #fff;
  }
  tbody tr:nth-child(even) td {
    background-color: #fff;
  }
  .no-padding {
    padding: 0px;
  }
  .border-left {
    border-left: 1px solid #b5b7ba;
  }
  .main-header {
    background-color: ${(p) => p.theme.palette.primary.light};
    p {
      color: ${(p) => p.theme.palette.primary.main};
    }
  }
  .disabled {
    background-color: #d2d4d5;
  }
  .white {
    background-color: #fff;
  }
`;

function Table<T extends object = {}>({ instance }: Props<T>) {
  const {
    pageOptions,
    data,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    pageCount,
    selectedFlatRows,
    rows,
    manualPagination,
    setHiddenColumns,
    allColumns,
    state: { pageIndex, pageSize },
    visibleColumns,
    onExport,
    onOpen,
    totalCount = 0,
  } = instance;

  console.log("DATA", rows);

  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  //  console.log('-----> visibleColumns:',visibleColumns);
  //  console.log('-----> instance:',instance);
  //  console.log('-----> headerGroups:',headerGroups);
  //console.log('-----> row:',rows);
  // Seleced rows table
  return (
    <>
      <StyledTableContainer>
        <StyledTable size="small">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <HeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <HeaderCell<T> key={column.id} column={column} />
                ))}
              </HeaderRow>
            ))}
          </TableHead>

          <TableBody>
            {(page ?? rows).map((row, i) => {
              prepareRow(row);
              return (
                <BodyRow
                  {...row.getRowProps()}
                  onClick={() => {
                    onOpen && onOpen(row);
                  }}
                >
                  {row.cells.map((cell) => (
                    <BodyCell<T>
                      columnId={cell.column.id}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </BodyCell>
                  ))}
                </BodyRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>

      {page && (
        <Pagination
          mobile={mobile}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          totalCount={manualPagination ? totalCount : data.length}
        />

        // <Footer
        //   //mobile={mobile}
        //   onExport={onExport}
        //   pageCount={pageCount}
        //   pageIndex={pageIndex}
        //   pageSize={pageSize}
        //   setPageSize={setPageSize}
        //   gotoPage={gotoPage}
        //   canNextPage={canNextPage}
        //   canPreviousPage={canPreviousPage}
        //   previousPage={previousPage}
        //   nextPage={nextPage}
        //   totalCount={manualPagination ? pageOptions.length : data.length}
        // />
      )}
    </>
  );
}

export default Table;
