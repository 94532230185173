import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { responsiveFontSizes } from "@material-ui/core/styles";
import { Overrides } from "@material-ui/core/styles/overrides";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

export const themeFonts = ["'Fira Sans'", "sans-serif"] as const;

export const defaultFonts = themeFonts.join(",");

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

export type DefaultFontsType = ElementType<typeof themeFonts>;

// crete object of overrides
const overrides: Overrides = {
  MuiGrid: {
    container: {
      margin: "0 !important",
      width: "100% !important",
    },
  },
  MuiButton: {
    contained: {
      color: "white",
    },
    root: {
      textTransform: "none",
    },
  },
  MuiFormLabel: {
    root: {
      "&$focused": {
        color: "black",
      },
      "&$error": {
        color: "black",
      },
    },
  },
  //@ts-ignore
  MuiAutocomplete: {
    paper: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      margin: 0,
    },
    // popper: {
    //   width: "100% !important",
    // }
  },
  MuiStep: {
    root: {
      "& .MuiStepConnector-root": {
        backgroundColor: "transparent !important",
      },
      "& .Mui-disabled": {
        backgroundColor: "transparent !important",
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: "#EEEAF5",
      color: "black",
      
    },
  },
};

const palette: PaletteOptions = {
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  background: {
    paper: "#FFFFFF",
    default: "#ffffff",
  },
  primary: {
    light: "#E6F6F5",
    main: "#765EB5",
    dark: "#0789A0",
    contrastText: "#FFFFFF",
  },
  secondary: {
    light: "#A798CF",
    main: "#765eb5",
    dark: "#513a8f",
    contrastText: "#FFFFFF",
  },
  error: {
    light: "#e57373",
    main: "#CC0000",
    dark: "#CC0000",
    contrastText: "#fff",
  },
  text: {
    primary: "#000000",
    secondary: "#928F88",
    disabled: "#B5B7BA",
    hint: "#00000099",
  },
  grey: {
    "300": "#DEE1EB",
  },
};

// get mui theme
const muiTheme = createMuiTheme({
  palette: {
    ...palette,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 667,
      md: 812,
      lg: 1024,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: defaultFonts,
    fontSize: 14,
    // fontWeightLight: 300, // Work Sans
    // fontWeightRegular: 400, // Work Sans
    // fontWeightMedium: 700 // Roboto Condensed
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    // MuiButtonBase: {
    //   // The properties to apply
    //   disableRipple: true // No more ripple, on the whole application 💣!
    // },
    MuiLink: {
      underline: "always",
      color: "textPrimary",
    },
    // MuiMenu: {
    //   transitionDuration: 0
    // }
  },
  // transitions: {
  //   // So we have `transition: none;` everywhere
  //   create: () => 'none',
  // },
  overrides: {
    ...overrides,
  },
});

// add overrides to the app theme
const theme = responsiveFontSizes(muiTheme);

export default theme;
