import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    marginLeft: '28px',
    width: "20.12rem",
    maxWidth: "45%",
    '& .MuiInputLabel-filled': {      
      transform: "translate(12px, 0.75rem) scale(1)",
      fontSize: '0.9rem',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {      
      transform: "translate(12px, -1rem) scale(1)",
      opacity:'0',
    },
   // background: "#F3F4F6",
    '& .MuiFilledInput-root': {
      background: "#F3F4F6",
      borderRadius: "1rem",      
      '&:after' : {
        borderBottom: '0px',
    },
      '& .MuiOutlinedInput-notchedOutline' : {
        borderColor: '#000',
        borderWidth: '2px',
    },
     // height: "2.2rem",
     '& .MuiFilledInput-inputAdornedEnd': {
        padding: "0.6rem",
      },
      '& .MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
        marginTop: "0.3rem",
        cursor: 'pointer',
      },
    },
    '& .MuiFilledInput-root:before': {
      borderBottom: "0px",
    }
  },
}));

export const SearchField = () => {
  const classes = useStyles();

  return (
    <TextField
    className={classes.margin}
    id="input-with-icon-textfield"
    label="Cerca articolo.." variant="filled"
    InputProps={{
      endAdornment: (
        <InputAdornment position="start">
          <SearchIcon /> 
        </InputAdornment>
      ),
    }}
  />
  );

}

export default SearchField;
