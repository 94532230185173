import React, { ReactNode } from "react";
import { FilterRequest } from "types/api";


export type FilterContextModel = {
  filter: FilterRequest;
  setFilter: React.Dispatch<React.SetStateAction<FilterRequest>>;
  searchResult: any[]
  setSearchResult: React.Dispatch<React.SetStateAction<any[]>>;
  // endpoint: string | null;
  // setEndpoint: React.Dispatch<React.SetStateAction<string | null>>;
};

export const initialFilter: FilterRequest = {
    search: ""
  };

export const initialFilterContext: FilterContextModel = {
  filter: initialFilter,
  setFilter: () => {},
  searchResult: [],
  setSearchResult: () => {},
  // endpoint: null,
  // setEndpoint: () => {}
};

export const FilterContext = React.createContext<FilterContextModel>(
  initialFilterContext
);

export const FilterContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [filter, setFilter] = React.useState<FilterRequest>(initialFilter);
  //const [endpoint, setEndpoint] = React.useState<string | null>(null);
  const [searchResult, setSearchResult] = React.useState<any[]>([])

  const value: FilterContextModel = {
    filter,
    // endpoint,
    // setEndpoint,
    setFilter,
    searchResult,
    setSearchResult
  };

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};
