import React from "react";
import MenuDrawer from "components/menu/MenuDrawer";
import { history } from "App";
import { useMutation } from "react-query";
import { fetcher } from "services";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";

const LeftContent = () => {
  const [request] = useMutation<any, AxiosError, string>((url) => fetcher.post(url));
  
  const location = useLocation();
  // creazione, modifica o dettaglio
  const currentPath: any = React.useMemo(
    () => location.pathname.replace("//d.*/", "").split("/").slice(1)[0],
    [location]
  );

  const onClick = React.useCallback(
    (url: string) => {
      if (url.startsWith("api")) {
        request(url.replace("api", ""));
      } else {
        history.push(url);
      }
    },
    [request]
  );

  return <MenuDrawer currentPath={currentPath} onClick={onClick} />;
};

export default LeftContent;
