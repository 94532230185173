import styled, {css} from "styled-components";
import TableRow  from "@material-ui/core/TableRow";

const sharedStyle = css`
` 
export const ROW_HEIGHT = 52;

export const HeaderRow = styled(TableRow)`
  ${sharedStyle}
  height: ${ROW_HEIGHT}px;
  background-color: #dee1e2;
`;


export const BodyRow = styled(TableRow)`
  height: ${ROW_HEIGHT}px;
  ${sharedStyle}
`;
