import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components";
import { MenuType, SubmenuType } from "routes/menu";

const StyledList = styled(List)`
  margin-left: 46px;
`;

const StyledListItem = styled(ListItem)`
  position: relative;
  padding: 16px;
`;

const StyledListItemText = styled(ListItemText)`
    padding-left: 10px;
    & > span {
      font-size: .875rem !important;
    }
`;

type Props = {
  submenu: SubmenuType[];
  handleClick: (url?: string, index?: number) => (event?: any) => void;
  selectedMenu?: number;
};

const NestedMenu = ({ submenu, handleClick, selectedMenu }: Props) => (
  <StyledList disablePadding>
    {submenu.map((sm, subindex) => (
      <StyledListItem
        key={"submenu-" + sm.label}
        button
        onClick={handleClick(sm.url)}
      >
        <StyledListItemText primary={sm.label} />
      </StyledListItem>
    ))}
  </StyledList>
);

export default NestedMenu;
