import React, { ChangeEvent } from "react";
import {
  PageButton,
  NextButton,
  PrevButton,
  SelectedPageButton
} from "./buttons";
import RowPerPageSelect from "../RowPerPageSelect";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const defaultRowPerPageOptions = [
  { value: 8, label: "8" },
  { value: 16, label: "16" },
  { value: 24, label: "24" },
  { value: 32, label: "32" },
  { value: 40, label: "40" }
];

const StyledRightContainer = styled.div`
  display: flex;
  ${p => p.theme.breakpoints.down("xs")} {
    width: 100%
  }
`;

const StyledTypography = styled(Typography)`
  padding: 8px;
  color: black;
  margin: auto;
  white-space: nowrap;
  margin-left: 24px;
  font-size:0.875rem;
`;

const StyledPageButtonContainer = styled.ul`
  margin: auto;
  list-style: none;
  padding: 8px;
  margin: 0;
  display: flex;
  ${p => p.theme.breakpoints.down("xs")} {
    width: 100%
  }
`;

const StyledPageNumberContainer = styled.div`
  padding: 8px;
  flex-grow: 1;
  color: black;
  white-space: nowrap;
`;

const StyledRootContainer = styled.nav`
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100%;
  background-color: white;
`;

const StyledListItem = styled.li`
  ${p => p.theme.breakpoints.down("xs")} {
    flex-grow: 1;
  }
  & button {
    width: 100%;
  }
`

// page index inizia da 1 
const createNumbers = (pageIndex: number, pageCount: number) => {
  const numbers = [pageIndex];

  const prevPages = 6 - Math.min(pageCount-pageIndex, 3);
  for (let i = 1; i < prevPages; i++) {
    const candidate = pageIndex - i;
    if (candidate > 0) {
      numbers.unshift(candidate);
    } else {
      break;
    }
  }

  const nextPages = 6 - numbers.length;
  for (let i = 1; i <= nextPages; i++) {
    const candidate = pageIndex + i;
    if (candidate <= pageCount) {
      numbers.push(candidate);
    } else {
      break;
    }
  }

  return numbers;
};

type Props = {
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (pageSize: number) => void;
  totalCount: number;
  mobile: boolean;
};

function Pagination({
  canNextPage,
  canPreviousPage,
  gotoPage,
  pageCount,
  pageSize,
  pageIndex,
  nextPage,
  previousPage,
  setPageSize,
  totalCount,
  mobile
}: Props) {
  const pageLabel = pageIndex + 1;
  const handlePageSize = (e: ChangeEvent<HTMLSelectElement>) =>
    setPageSize(Number(e.target.value));

  const handlePageChange = (pageNumber: number) => () => gotoPage(pageNumber);

  const numbers = createNumbers(pageLabel, pageCount);
  const vistiMax = pageLabel * pageSize;
  const visti = vistiMax > totalCount ? totalCount : vistiMax
  //console.log("pageLabel="+pageLabel+" - pageSize:"+pageSize+" - vistiMax:"+vistiMax+" - totalCount:"+totalCount);
  const textBeforePagination = `Mostra da ${(pageLabel - 1) * pageSize +
    1} a ${visti} di ${
    totalCount
  } elementi`;

  return (
    <StyledRootContainer>
      <StyledPageNumberContainer>
        <RowPerPageSelect
          label={mobile ? "Visualizza" : "Visualizza elementi"}
          onChange={handlePageSize}
          options={defaultRowPerPageOptions}
          selectedVal={pageSize}
        />
      </StyledPageNumberContainer>
      <StyledTypography>{textBeforePagination}</StyledTypography><StyledRightContainer>
        
        <StyledPageButtonContainer>
          <StyledListItem>
            <PrevButton onClick={previousPage} disabled={!canPreviousPage}>
              {mobile ? "Prec." : "Precedente"}
            </PrevButton>
          </StyledListItem>
          {numbers.map((number, index) =>
            number === pageLabel ? (
              <StyledListItem key={index}>
                <SelectedPageButton onClick={handlePageChange(number-1)}>
                  {number}
                </SelectedPageButton>
              </StyledListItem>
            ) : (
              <StyledListItem key={index}>
                <PageButton onClick={handlePageChange(number-1)}>{number}</PageButton>
              </StyledListItem>
            )
          )}
          <StyledListItem>
            <NextButton onClick={nextPage} disabled={!canNextPage}>
              Succ.
            </NextButton>
          </StyledListItem>
        </StyledPageButtonContainer>
      </StyledRightContainer>
    </StyledRootContainer>
  );
}

export default Pagination;
