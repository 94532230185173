import styled from "styled-components";

export const BodyContainer = styled.div`
  flex: 0 0 100%;
  background:#fff; 
  min-height: calc(100vh - 170px);
`;

export const MainContent = styled.div`
  flex-basis: 100%;
  position: relative;
  /* background: ${p => p.theme.palette.background.paper}; */
`;

export const Root = styled.div`
  display: flex;
`;


export const ToolbarSpace = styled.div((p) => p.theme.mixins.toolbar as any);