export const Url = {
  home: "/",
  magazzinofederato: "/magazzinofederato",
  magazzinofederato_backoffice: "/magazzinofederato-backoffice",
  aggiornamagazzino: "/aggiornamagazzino",
  aggiornascalarini: "/aggiornamagazzino/:scalarini",
  prodotto: "/prodotto/:productItemId",
  prodottofederato: "/magazzinofederato/prodotto/:productItemId",
  prodottoscheda: "/prodotto-scheda/:productItemId",
  prodottofederatoscheda: "/magazzinofederato/prodotto-scheda/:productItemId",
  login: "/login",
  createuser: "/createuser",
  register: "/register",
  reset: "/reset",
  privacy: "/privacy",
  activate:
    "/activate_back" /* TODO: VECCHIA ROTTA: vedere se cambiando questa rotta cambia qualcosa sul sito */,
  forgot: "/forgot",
  modificabrand: "/brands/:brandId",
  nuovobrand: "/brands/nuovobrand",
  brands: "/brands",
  notfound: "/notfound",
  profilo: "/profilo",
  profilobackoffice: "/profilobackoffice",
  ordini: "/ordini",
  ordini_backoffice: "/ordini-backoffice",
  dettaglioordine: "/ordini/dettaglioordine/:ordineId",
  resi: "/resi",
  resi_backoffice: "/resi-backoffice",
  statistiche: "/statistiche",
  dettaglioreso: "/resi/dettaglioreso/:resoId",
  societafederate: "/societafederate",
  societafederate_creazione: "/societafederate/creazione",
  societafederate_modifica: "/societafederate/societa/:retailerId",
  help: "/help",
  nuovoticket: "/nuovoticket",
  dettaglioticket: "/dettaglioticket/:ticketId",
  techsupport: "/tech-support",
  notifiche: "/notifiche",
  vedinotifica: "/notifiche/:notificaId",
  utentibackoffice: "/utentibackoffice",
  app_socialcommerce: "/app-social-commerce",
  app_socialcommerce_preferenze: "/app-social-commerce/preferenze",
  app_socialcommerce_backoffice: "/app-social-commerce-backoffice",
  primo_accesso: "/first_access",
  activate_user: "/activate",
  retailer_profile: "/retailer_profile",

  info_retailer_registration: "/info_retailer_registration",

  Configurazione: {
    Utenti: {
      gestione: "/configurazione/utenti/gestione",
      creazione: "/configurazione/utenti/creazione",
      modifica: "/configurazione/utenti/modifica/:id",
    },
    Aziende: {
      gestione: "/configurazione/aziende/gestione",
      creazione: "/configurazione/aziende/creazione",
      modifica: "/configurazione/aziende/modifica/:id",
    },
  },
};

export type Mode = "creazione" | "dettaglio" | "modifica";
