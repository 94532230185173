import React from "react";
import styled from "styled-components";
import {Trans} from "react-i18next";

const Root = styled.div`
    height: 80px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const FooterContent = () => <Root>© 2020 - Luxury Federated warehouse v.1.0.0</Root>
