import React, { useEffect, useContext, useState} from "react";
import { Column, Row } from "react-table";
import { TableNotifiche } from "types/api";
import { AuthContext } from "contexts/AuthContext";
import { PageTable } from "components/page-table/PageTable";
import { goTo, Url } from "routes";
import LinkButton from "components/linkbutton/LinkButton";
import classes from "./AnteprimaNotifiche.module.scss"; 
import { Controller, useForm } from "react-hook-form";
import { useGet, usePost, useGetMutation } from "services";
import { PageLoading } from "template/PageLoading";
import { PaginatedResponse, PaginatedRequest } from "types/api";
import Heading from "components/page-heading/Heading";

type RowType = TableNotifiche;



type FormType = {
  productname?: string;
  productcode?: string;
  brand?: string;
};


type Props = {
  onClose: any;
};

type Request = FormType & Partial<PaginatedRequest>; 


const AnteprimaNotifiche = ({ onClose }: Props) => {
  const { setTotNotifiche, nuoveNotifiche } = useContext(AuthContext);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [segnaNotificheLette, { isLoading: notificheletteLoading, error: notificheLetteErr }] = usePost<undefined, any>(`Notifications/MarkAllAsRead`);
  //const { data: totNotifiche, error, isLoading} = useGet<any>(`Notifications/UserNotificationCount`);
  const [totNotifiche] = useGetMutation<any,any>(`Notifications/UserNotificationCount`);
  const [refetchTable, doRefetchTable] = useState(0);   
    
  const columns: Column<RowType>[] = [

    {
      Header: "ID",
      accessor: "id",
      id: "id",
    },
    {
      Header: "Ordine LFW",
      accessor: "type",
      id: "type",
      Cell: (p) => (  
        <img alt="Notifica"  src={ p.row.original.type == "LFWTeam"  ? "/images/icons/notifica_msg.svg"  : "/images/icons/notifica_txt.svg"  }
        className={`${classes.imgnotifica} ${(!p.row.original.isRead) && classes.imgbold} `}
      /> ),
    },
    {
      Header: "title",
      accessor: "title",
      id: "title",
      Cell: (p) => (
        <p className={`${classes.boxinfo} ${(!p.row.original.isRead) && "bold"} `}>
            <span>{p.row.original.title}</span>
            <small>{p.row.original.timeElapsed}</small>
        </p>
      ),
    }, 

  ];

  const impostaTotNotifiche = async () => { 
    await totNotifiche({ throwOnError: true })
    .then(dati => { 
      console.log('NOTIFICHE SETTATE');
      setTotNotifiche(dati); 
    })
    .catch(error => { })    
  }

  useEffect(() => {        
    impostaTotNotifiche();
  },[]);

  const segnaNotificheComeLette = async() => {
    await segnaNotificheLette({ throwOnError: true })
    .then(res => {  vediNotifiche(); })
    .catch(error => console.log("ERRORE:",error.message)); 
    doRefetchTable(prev => prev + 1); 
  }    

  const vediNotifiche = () => {
    goTo(Url.notifiche);
    onClose();
  };
  
  const vediNotifica = (id: any) => {
    goTo(Url.vedinotifica.replace(":notificaId", id));
    onClose();
  };


  const { register, handleSubmit,  control, errors, reset } = useForm();
  const [request, setRequest] = React.useState<Request>({
    orderBy: "date",
    rowsForPage: 5,
  });
  const onSubmit = (data: FormType) => {
    console.log("data=", data);
    console.log("request=", request);
    setRequest({ ...request, ...data });
  };

  return (
    <div className={classes.container} >
      <header>
          <h3>{nuoveNotifiche} Nuove Notifiche</h3> 
          <LinkButton
              className={classes.linkbutton}
              text="Segna tutte come lette"
              onClick={() => { segnaNotificheComeLette()  }}
            />
       </header>
     
        <PageTable
          columns={columns}
          idName="id"         
          onOpen={(row) =>  vediNotifica(row?.original.id)  }
          tableUrl="Notifications"
          filter={request}
          refresh={refetchTable}
        />
      <LinkButton
          className={classes.linkbutton}
          text="Vedi tutte"
          onClick={()=> { vediNotifiche();  } }
        />        
    </div>
  );
};

export default AnteprimaNotifiche;
