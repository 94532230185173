import React, { Suspense } from "react";
import "App.scss";
import { Router } from "react-router-dom";
import {
  ThemeProvider as ThemeProviderMui,
  StylesProvider,
} from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import theme from "themes/default";
import Routes from "routes";
import ScrollToTop from "routes/components/ScrollToTop";
import { createBrowserHistory, BrowserHistoryBuildOptions } from "history";
import { AuthContextProvider } from "contexts/AuthContext";
import { ReactQueryConfigProvider } from "react-query";
import { AuthenticateOnStart } from "routes/components/AuthenticateOnStart";
import { LayoutContextProvider } from "contexts/LayoutContext";
import { FilterContextProvider } from "contexts/FilterContext";
import { CartContextProvider } from "contexts/CartContext";
import { queryConfig } from "config/react-query";
import "config/i18n";
import "config/top-bar-progress";
import { PageLoading } from "template/PageLoading";
import { DatePickerProvider } from "config/date-picker-provider";
import { ReactQueryDevtools } from "react-query-devtools";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
export const history = createBrowserHistory({
  basename: baseUrl,
} as BrowserHistoryBuildOptions);

const App = () => (
  <StylesProvider injectFirst>
    <ThemeProviderMui theme={theme}>
      <ThemeProvider theme={theme}>
        <ReactQueryConfigProvider config={queryConfig}>
          <AuthContextProvider>
            <DatePickerProvider>
              <LayoutContextProvider>
                <CartContextProvider>
                  <FilterContextProvider>
                    <Router history={history}>
                      <Suspense fallback={<PageLoading />}>
                        <AuthenticateOnStart>
                          <ScrollToTop>
                            <Routes />
                            {process.env.NODE_ENV === "development" && (
                              <ReactQueryDevtools />
                            )}
                          </ScrollToTop>
                        </AuthenticateOnStart>
                      </Suspense>
                    </Router>
                  </FilterContextProvider>
                </CartContextProvider>
              </LayoutContextProvider>
            </DatePickerProvider>
          </AuthContextProvider>
        </ReactQueryConfigProvider>
      </ThemeProvider>
    </ThemeProviderMui>
  </StylesProvider>
);

export default App;
