import React, { ReactNode } from "react";
import { storage } from "services";

export type LayoutContextModel = {
  openMenu: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
  expandMenu: boolean;
  setExpandMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export const initialLayoutContext: LayoutContextModel = {
  openMenu: false,
  setOpenMenu: (open: any) => {},
  expandMenu: true,
  setExpandMenu: (expand: any) => {},
};

export const LayoutContext = React.createContext<LayoutContextModel>(
  initialLayoutContext
);

export const LayoutContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [expandMenu, setExpandMenu] = React.useState(true);

  const LayoutContextValue: LayoutContextModel = {
    openMenu,
    setOpenMenu,
    expandMenu,
    setExpandMenu,
  };

  return (
    <LayoutContext.Provider value={LayoutContextValue}>
      {children}
    </LayoutContext.Provider>
  );
};
