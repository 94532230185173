import React, { useContext } from "react";
import styled from "styled-components";
import Icon from "components/buttons/Icon";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles";
import { AuthContext } from "contexts/AuthContext";

const StyledHeader = styled(List)`
  background: #1e2a32; 
  height: 9.2rem;
  padding-top: 1.2rem;
  padding-left: 0.7rem;
`;

const StyledAvatar = styled(({ expand, ...rest }) => <Avatar {...rest} />)<{
  expand: boolean;
}>`
  background-color: initial;
  transition: transform 0.2s linear;
  ${(p) => p.expand && `transform: rotate(90deg);`}
`;

const Space = styled.div`
  flex-grow: 1;
`;

type HeaderProps = {
  expand: boolean;
};

const MenuHeader = ({ expand }: HeaderProps) => {
  //const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  //const { openMenu, setOpenMenu } = useContext(AuthContext);

  return (
    <StyledHeader>
      <ListItem>
        {expand && <img alt="" src="/images/icons/logo-menu.svg" />}
        {!expand && <img alt="" src="/images/icons/logo-menu-min.svg" />}
        <Space />
      </ListItem>
    </StyledHeader>
  );
};

export default MenuHeader;
