import { fetcherSYNC, fetcher } from "./fetcher";
import {
  useMutation,
  useQuery,
  usePaginatedQuery,
  queryCache,
  useInfiniteQuery,
  MutationConfig,
  QueryConfig,
  InfiniteQueryConfig,
} from "react-query";
import { PaginatedRequest, PaginatedResponse } from "types/api";
import qs from "qs";
import { AxiosError } from "axios";

const paramsSerializer = (params: any) => {
  return qs.stringify(params, { arrayFormat: "repeat" });
};

export function usePost<RESULT = undefined, REQUEST = any>(
  url: string,
  mutationOptions?: MutationConfig<RESULT, AxiosError, REQUEST>
) {
  const request = useMutation<RESULT, AxiosError, REQUEST>(
    (values) => fetcher.post(url, values).then((r) => r.data),
    mutationOptions
  );
  return request;
}

export function usePostSYNCendpoint<RESULT = undefined, REQUEST = any>(
  url: string,
  mutationOptions?: MutationConfig<RESULT, AxiosError, REQUEST>
) {
  const request = useMutation<RESULT, AxiosError, REQUEST>(
    (values) => fetcherSYNC.post(url, values).then((r) => r.data),
    mutationOptions
  );
  return request;
}

// export function usePostSYNCendpoint<RESULT = undefined, REQUEST = any>(
//   url: string,
//   method: "POST" | "PUT" = "POST",
//   mutationOptions?: MutationConfig<RESULT, AxiosError, REQUEST>,
// ) {
//   const request = useMutation<RESULT, AxiosError, REQUEST>((values) => {
//     console.log("MIEIVALORI:");
//     console.log(values);
//     //    var myJsonString = JSON.stringify(values);
//     //  const fd = new FormData();
//     // Object.keys(values).forEach((key: any, index: number) => {
//     //   const value = (values as any)[key];
//     //   if(Array.isArray(value)){
//     //     value.forEach(x=> fd.append(key, x))
//     //   }
//     //   else{
//     //      fd.append(key, value);
//     //   }
//     // });
//      return fetcherSYNC({ url, data: values, method, headers: { "Content-Type": "application/json-patch+json",  }, }).then((r) => r.data);

//   }, mutationOptions);
//   return request;
// }

export function useDelete<RESULT, REQUEST = undefined>(
  url: string,
  mutationOptions?: MutationConfig<RESULT, AxiosError, REQUEST>
) {
  const request = useMutation<RESULT, AxiosError, REQUEST>(
    (values) => fetcher.delete(url, values).then((r) => r.data),
    mutationOptions
  );
  return request;
}

export function useDeleteSYNCendpoint<RESULT, REQUEST = undefined>(
  url: string,
  mutationOptions?: MutationConfig<RESULT, AxiosError, REQUEST>
) {
  const request = useMutation<RESULT, AxiosError, REQUEST>(
    (values) => fetcherSYNC.delete(url, values).then((r) => r.data),
    mutationOptions
  );
  return request;
}

export function usePut<RESULT, REQUEST = undefined>(
  url: string,
  mutationOptions?: MutationConfig<RESULT, AxiosError, REQUEST>
) {
  const request = useMutation<RESULT, AxiosError, REQUEST>(
    (values) => fetcher.put(url, values).then((r) => r.data).catch((error) => {
      if (error.response?.data) {
        return error.response.data;
      }
      return error;
    }),
    mutationOptions
  );
  return request;
}

export function usePutSYNCendpoint<RESULT, REQUEST = undefined>(
  url: string,
  mutationOptions?: MutationConfig<RESULT, AxiosError, REQUEST>
) {
  const request = useMutation<RESULT, AxiosError, REQUEST>(
    (values) => fetcherSYNC.put(url, values).then((r) => r.data),
    mutationOptions
  );
  return request;
}

// useMutation per HTTP GET
export function useGetMutation<RESULT, REQUEST = undefined>(
  url: string,
  mutationOptions?: MutationConfig<RESULT, AxiosError, REQUEST>
) {
  const request = useMutation<RESULT, AxiosError, REQUEST>(
    (values) =>
      fetcher
        .get(url, { params: values, paramsSerializer })
        .then((r) => r.data),
    mutationOptions
  );
  return request;
}

// useMutation per HTTP GET
export function useGetMutationSYNCendpoint<RESULT, REQUEST = undefined>(
  url: string,
  mutationOptions?: MutationConfig<RESULT, AxiosError, REQUEST>
) {
  const request = useMutation<RESULT, AxiosError, REQUEST>(
    (values) =>
      fetcherSYNC
        .get(url, { params: values, paramsSerializer })
        .then((r) => r.data),
    mutationOptions
  );
  return request;
}

export function useGet<RESULT, REQUEST = any>(
  url: string,
  values?: REQUEST,
  options: QueryConfig<RESULT, AxiosError> = {}
) {
  const key = values ? [url, values] : url;
  const request = useQuery<RESULT, AxiosError, any>(
    key,
    () =>
      fetcher
        .get(url, { params: values, paramsSerializer })
        .then((r) => r.data),
    options
  );

  return request;
}

export function usePaginatedGet<RESULT, REQUEST = any>(
  url: string,
  values: REQUEST,
  options: QueryConfig<RESULT, AxiosError> = {}
) {
  const key = values ? [url, values] : url;
  const request = usePaginatedQuery<RESULT, AxiosError, any>(
    key,
    () =>
      fetcher
        .get(url, { params: values, paramsSerializer })
        .then((r) => r.data),
    options
  );

  return request;
}

export function useUpload<RESULT = undefined, REQUEST = any>(
  url: string,
  SYNCendpoint?: boolean,
  method: "POST" | "PUT" = "POST",
  mutationOptions?: MutationConfig<RESULT, AxiosError, REQUEST>
) {
  const request = useMutation<RESULT, AxiosError, REQUEST>((values) => {
    const fd = new FormData();
    console.log("values", values);
    Object.keys(values).forEach((key: any, index: number) => {
      const value = (values as any)[key];
      if (Array.isArray(value)) {
        value.forEach((x) => fd.append(key, x));
      } else {
        fd.append(key, value);
      }
    });

    if (SYNCendpoint) {
      console.log("API SYNCendpoint:" + SYNCendpoint);
      return fetcherSYNC({
        url,
        data: fd,
        method,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((r) => r.data);
    } else {
      return fetcher({
        url,
        data: fd,
        method,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((r) => r.data);
    }
  }, mutationOptions);
  return request;
}

export function useDownload(
  mutationOptions?: MutationConfig<any, AxiosError, string>
) {
  const request = useMutation<any, AxiosError, string>((url) => {
    return fetcher({
      method: "GET",
      url,
      responseType: "arraybuffer",
    }).then((response) => {
      let filename = "attachment";
      const disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      const type = response.headers["content-type"];
      const link = document.createElement("a");
      const base64 = Buffer.from(response.data, "binary").toString("base64");
      link.href = `data:${type};base64,${base64}`;
      link.setAttribute("download", filename);
      link.click();
      //link.remove();
    });
  }, mutationOptions);
  return request;
}

// function str2bytes (str: any) {
//   var bytes = new Uint8Array(str.length);
//   for (var i=0; i<str.length; i++) {
//       bytes[i] = str.charCodeAt(i);
//   }
//   return bytes;
// }

export function useInfiniteGet<
  RESULT,
  REQUEST extends PaginatedRequest = PaginatedRequest
>(
  url: string,
  values: REQUEST,
  options: InfiniteQueryConfig<PaginatedResponse<RESULT>, AxiosError> = {
    getFetchMore: () => false,
  }
) {
  const { pageNumber, ...rest } = values;
  const key = [url, rest];
  const request = useInfiniteQuery<
    PaginatedResponse<RESULT>,
    AxiosError,
    [string, unknown, REQUEST]
  >(
    key,
    (key1, key2, v = values) => {
      return fetcher
        .get(url, { params: v, paramsSerializer })
        .then((r) => r.data);
    },
    {
      ...options,
      getFetchMore: (lastGroup, allGroups) => {
        const totalPages = Math.ceil(lastGroup.totalCount / values.rowsForPage);
        const currentPage = allGroups.length;

        if (currentPage < totalPages) {
          return { ...values, pageNumber: currentPage };
        }
        return false;
      },
    }
  );

  return request;
}

export function prefetch<REQUEST = any, RESULT = any>(
  url: string,
  values: REQUEST,
  config?: any
) {
  const key: any = values ? [url, values] : url;
  queryCache.prefetchQuery<RESULT, AxiosError, any>(
    key,
    () =>
      fetcher
        .get(url, { params: values, paramsSerializer })
        .then((r) => r.data),
    { ...config, prefetch: true }
  );
}

export function updateCache<REQUEST = any, RESULT = any>(
  url: string,
  values: REQUEST,
  newCache: any,
  config?: QueryConfig<RESULT, AxiosError>
) {
  const key: any = values ? [url, values] : url;
  queryCache.setQueryData<RESULT, AxiosError>(key, newCache, config);
}
