import React from "react";
import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import Icon from "components/buttons/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { AuthContext, LayoutContext } from "contexts";

const StyledContainer = styled(ListItem)`
  padding-top: 0.82rem;
  padding-bottom: 0.82rem; 
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  & .MuiTypography-body1 {   font-size: 0.86rem;  }      
  & .MuiListItemAvatar-root {
      min-width: 56px;
      flex-shrink: 0;
      justify-content: center;
      display: flex;
  }
  &:hover {
     background-color: rgba(255, 255, 255, 0.25);
  } 
`;

export const Logout = () => {

  const {logout} = React.useContext(AuthContext);
  const {expandMenu} = React.useContext(LayoutContext);

  return (
    <StyledContainer onClick={() => logout()} button>
       <ListItemAvatar>
      <Icon src="/images/icons/logout.svg" />
      </ListItemAvatar>
      <ListItemText primary="Logout" />
      {/* {expandMenu && <ListItemText primary="Logout" />}       */}
    </StyledContainer>
  );
};
