import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useLocation } from "react-router-dom";

// role needed to access url
const RolesNameMap: Record<string, string> = {
  //"/": "ConfigurazioneVisualizzazioneUtenti",
  "/configurazione/utenti/gestione": "Retailer",
  "/statistiche": "Retailer",
};




// hook: determines whether the user has access to the current url
// throwing exception if hasn't
export function usePageRights() {
  const { account } = useContext(AuthContext);
  const location = useLocation();
  if (account) {
    const path = location.pathname.replace("//d.*/", "");
    const rightNeeded = RolesNameMap[path];
    if (!rightNeeded || (account.diritti === rightNeeded)) {
      return true;
    }
  } 
  return false;
}

// hook: returns a function which determines if button can be used
// enabling/disabling the button
export function useRights() {
  const { account } = useContext(AuthContext);
  return (...rightNeeded: string[]) =>
    //account && Boolean(rightNeeded.find(n => account.diritti?.find((r: string) => n === r)));
   // console.log("rightNeeded="+rightNeeded+" - account.diritti=",account?.diritti); 
    account && Boolean(rightNeeded.find(el => el === account?.diritti));

 

}
