import React, { ChangeEvent } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import styled from "styled-components";
import InputBase from "@material-ui/core/InputBase";
import NativeSelect, {
  NativeSelectProps
} from "@material-ui/core/NativeSelect";
import Label from "@material-ui/core/FormLabel";

const StyledInput = styled(InputBase)`
  & .MuiInputBase-input {
    border-radius: 5px;
    position: relative;
    background-color: white;
    border: 1px solid #ced4da;
    font-size: 0.8rem;
    padding: 10.5px 34px 10.5px 12.5px;
    margin-left: 17.5px;
    margin-right: -6px;
  }
`;

const StyledLabel = styled(Label)`
  color: black;
  font-size: 0.875rem;
`;

type Option = {
  value: any;
  label: string;
};

type Props = {
  label: string;
  options: Option[];
  selectedVal: any;
} & NativeSelectProps;

const RowPerPageSelect = ({ label, options, selectedVal, ...rest }: Props) => {
  return (
    <div>
      <StyledLabel htmlFor={rest.id}>{label}</StyledLabel>
      <NativeSelect
        IconComponent={KeyboardArrowDownIcon}
        {...rest}
        input={<StyledInput />}
      >
        {options.map(o => (
          <option key={o.value} value={o.value} selected={(o.value==selectedVal)} >{o.label}</option>
        ))}
      </NativeSelect>
    </div> 
  );
};

export default RowPerPageSelect;
