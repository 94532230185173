import React, {
  ReactNode,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";

export type CartContextModel = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

export const initialCartContext: CartContextModel = {
  isDrawerOpen: false,
  setIsDrawerOpen: (open: any) => {},
};

export const CartContext = createContext<CartContextModel>(initialCartContext);

export const CartContextProvider = ({ children }: { children: ReactNode }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const CartContextValue: CartContextModel = {
    isDrawerOpen,
    setIsDrawerOpen,
  };

  return (
    <CartContext.Provider value={CartContextValue}>
      {children}
    </CartContext.Provider>
  );
};
